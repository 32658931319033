import { AxiosResponse } from "axios";
import { Reports as ReportsApi } from "../api/Reports";
import { EmbedReportResponse } from "../api/data-contracts"
import { ServiceGetResult } from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";

const reportApi = new ReportsApi();
addAuthHandling(reportApi);

export type GetReportResult = ServiceGetResult<EmbedReportResponse>;

export const reportService = {
   getReport: async (tspId:number, fleetIds:string, language:string): Promise<AxiosResponse<EmbedReportResponse, any>> => {
      return await reportApi.getSafetyAnalyticsReport({ tspId:tspId, fleetIds:fleetIds, language:language });
    },
};
