import { Button } from "react-bootstrap";
import styles from "./SafetyAnalyticsHeader.module.scss";
import greenCirclePath from "../../assets/green_circle.svg";
import { useEffect } from "react";
import { useSafetyAnalyticsContext } from "../../context/SafetyAnalyticsContext ";

const SafetyAnalyticsHeader = () => {

  const { report, pages, setPages, setActivePage, activePage, goToPage } = useSafetyAnalyticsContext();

  useEffect(() => {
    if (!report) return;

    const loadPages = async () => {
      let pageList = await report.getPages();
      pageList = pageList.filter((p) => p.visibility === 0); // Only visible pages
      setPages(pageList);
      if (pageList.length > 0) {
        const currentPage = pageList.find((p) => p.name === activePage);
        setActivePage(currentPage? currentPage.name : pageList[0].name);
        await currentPage? currentPage!.setActive() : pageList[0].setActive();
      }
    };

    report.on("loaded", loadPages);

    return () => {
      report.off("loaded", loadPages);
    };
  }, [report,setPages,setActivePage,activePage]);
 
  
  return (
    <div className="d-flex flex-row align-items-center justify-content-center">
      {pages.map((page, index) => {
        const isFirst = index === 0;
        const isLast = index === pages.length - 1;
        const buttonClass = pages.length === 1? "round-button" : isFirst ? "left-button" : isLast ? "right-button" : "middle-button";

        return (
          <Button
            key={page.name}
            onClick={() => goToPage(page.name)}
            size="sm"
            style={{ minWidth: "128px", width: "fit-content" }}
            className={`text-nowrap toggle-button ${styles[buttonClass]}`}
          >
            {activePage === page.name && (
              <img className="green-circle" src={greenCirclePath} alt="gs" />
            )}
            <span style={{ marginLeft: "0.25rem", verticalAlign: "middle" }}>
              {page.displayName}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

export default SafetyAnalyticsHeader;
 