import React, { PropsWithChildren, useContext, useState } from "react";
import * as powerbi from "powerbi-client";

interface ISafetyAnalyticsContext {
  report: powerbi.Report | null;
  pages: powerbi.Page[];
  activePage: string | null;
  setReport: (report: powerbi.Report) => void;
  setPages: (report: powerbi.Page[]) => void;
  setActivePage: (page:string) => void;
  goToPage: (pageName: string) => void;

}

const SafetyAnalyticsContext  = React.createContext<ISafetyAnalyticsContext>({
  report: null,
  pages:[],
  activePage: null,
  setReport: () => {},
  setPages: () => {},
  goToPage: () => {},
  setActivePage: () => {}
});

export function SafetyAnalyticsContextProvider({ children }: PropsWithChildren<{}>) {
    const [report, setReport] = useState<powerbi.Report | null>(null);
    const [pages, setPages] = useState<powerbi.Page[]>([]);
    const [activePage, setActivePage] = useState<string | null>(null);

    const goToPage = async (pageName: string) => {
      if (report) {
        const pageList = await report.getPages();
        const targetPage = pageList.find((p) => p.name === pageName);
        if (targetPage) {
          await targetPage.setActive();
          setActivePage(pageName);
        }
      }
    };

  return (
    <SafetyAnalyticsContext.Provider value={{ report, pages, activePage,  setReport, setPages, goToPage, setActivePage }}>
      {children}
    </SafetyAnalyticsContext.Provider>
  );
}


export const useSafetyAnalyticsContext  = () => useContext(SafetyAnalyticsContext);
