import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useCallback, useContext, useState } from "react";
import { clientSettingQuery } from "../queries/clientSetting.query";

interface ILastUpdateContext {
  time: string;
  autoRefreshIntervalInSec?: number;
  invalidateQueries: () => void;
  updateTime: (time:string) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  // setRefreshFunction: (refreshFunction: () => void) => void;
  // refresh?: () => void;
}

const LastUpdateContext = createContext<ILastUpdateContext>({
  time: new Date().toLocaleTimeString(),
  autoRefreshIntervalInSec: 60,
  invalidateQueries: () => { },
  updateTime: (time:string) => { },
  isLoading: false,
  setIsLoading: () => { }
  // setRefreshFunction: () => { },
  // refresh: () => { },
});

export function LastUpdateContextProvider({ children }: PropsWithChildren<{}>) {
  const queryClient = useQueryClient();
  const { data } = useQuery(clientSettingQuery());
  const [time, setTime] = useState<string>(new Date().toISOString());
  const [isLoading, setIsLoading] = useState(false);
  //const [action, setAction] = useState<(() => Promise<void>)>(async () => { });

  const invalidateQueries = useCallback(async () => {
    await queryClient.invalidateQueries();
    setTime(new Date().toISOString());
  }, [queryClient]);

  const updateTime =  async (time:string) => {
    setTime(time);
  };

  // const setRefreshFunction = (refreshFunction: () => void) => {
  //   setAction(async () => refreshFunction());
  // }

  // const refresh = useCallback(() => {
  //   if (action) {
  //     setIsLoading(true);
  //     action().finally(() => {
  //       setIsLoading(false);
  //     });
  //   }
  // }, [action]);
  return <LastUpdateContext.Provider value={{ time, autoRefreshIntervalInSec: data?.autoRefreshIntervalInSec, invalidateQueries, updateTime, isLoading, setIsLoading }}>{children}</LastUpdateContext.Provider>;
}

export const useLastUpdateContext = () => useContext(LastUpdateContext);
