import { useQuery } from "@tanstack/react-query";
import { BaseDriverOptions, DriversSortFilterParamName, GetDriversParams, GetDriversResult } from "../services/driver.service";
import { FilterParam, ServiceGetResult } from "../models/requests/shared.request";
import { useDriversQueryParamsContext } from "../context/QueryParamsContext/DriversQueryParamsContextProvider";
import { useEffect } from "react";
import useFilterByTspFleetContext from "./useFilterByTspFleetContext";
import { driversQuery } from "../queries/drivers.query";
import { Driver } from "../api/data-contracts";

function useQueryDrivers<selectedT extends ServiceGetResult<unknown>>(
  selector: (queryResult: GetDriversResult) => selectedT,
  enabled: boolean = true
) {
  const { queryParams, setPageIndex, upsertFilter, removeFilter } = useDriversQueryParamsContext();
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(
    upsertFilter,
    removeFilter,
    queryParams.filters
  );
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;

  const { data, isLoading, isError, isFetching } = useQuery(driversQuery(queryParams, selector, showData && enabled));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return {
    data: showData ? data : undefined,
    isError,
    isLoading: isLoading && isFetching,
    isFetching,
  };
}

export function useQueryDriver(driver: BaseDriverOptions, enabled: boolean = true) {
  let queryParams: GetDriversParams = { filters: [], sorts: [], paging: { pageIndex: 1, pageSize: 10 } };
  if (enabled) {
    queryParams = {
      ...queryParams,
      filters: [
        { paramName: "driverId", operator: "==", values: [driver.driverId] },
        { paramName: "fleetId", operator: "==", values: [driver.fleetId.toString()] },
        { paramName: "tspId", operator: "==", values: [driver.tspId.toString()] },
      ],
    };
  }
  const { data, isLoading, isError, isFetching, refetch } = useQuery(driversQuery(queryParams, (x) => x, enabled));

  return {
    data: data?.data,
    isError,
    isLoading: isLoading && isFetching,
    isFetching,
    refetch,
  };
}

export function useQueryAvailableDeviceDrivers(
  drivers: Driver[],
  tspId?: number,
  fleetId?: number,
  enabled: boolean = false
) {
  const { queryParams } = useDriversQueryParamsContext();
  const { paging, sorts } = queryParams;
  const filterParam: GetDriversParams = {
    paging: paging,
    sorts: sorts,
    filters: [
      //...queryParams.filters, fix for AS-562
      {
        paramName: "tspId",
        operator: "==",
        values: tspId ? [tspId.toString()] : [],
      },
      {
        paramName: "fleetId",
        operator: "==",
        values: fleetId ? [fleetId.toString()] : [],
      },
    ],
  };
  let driverIds = drivers?.map((dr) => dr.driverId!);
  if (driverIds && driverIds.length > 0) {
   const driverFilter :FilterParam<DriversSortFilterParamName>[] = driverIds.map((driver)=> {
     return { paramName: "driverId", operator: "!=", values: [driver] }
   })
    filterParam.filters = [...filterParam.filters, ...driverFilter];
  }
  const { data, isLoading, isError, isFetching } = useQuery(driversQuery(filterParam, (d) => d, enabled));
  return {
    data: data,
    isError,
    isLoading: isLoading && isFetching,
    isFetching,
  };
}

export default useQueryDrivers;
