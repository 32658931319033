import { QueryFunctionContext } from "@tanstack/react-query";
import { GetEventsResult, NullablePagingGetEventParams } from "../services/events.service";
import { eventsService } from "../services/events.service";

export function eventsQuery<TResult>(
  params: NullablePagingGetEventParams,
  selector: (queryResult: GetEventsResult) => TResult,
  enabled: boolean
) {

  return {
    queryKey: ["events", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["events", NullablePagingGetEventParams]>) =>
      await eventsService.getEvents(context.queryKey[1]),
    select: selector,
    keepPreviousData: true,
    enabled: enabled,
    retry: false
  };
}


export function eventsTypeConfigQuery() {
  return {
    queryKey: ["events-type-config"] as const,
    queryFn: async () => await eventsService.getEventTypeCofnig(),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: true,
    retry: false
  };
}


export function eventsNextQuery<TResult>(
  params: NullablePagingGetEventParams,
  selector: (queryResult: GetEventsResult) => TResult,
  enabled: boolean
) {

  return {
    queryKey: ["eventNext", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["eventNext", NullablePagingGetEventParams]>) =>
      await eventsService.getEvents(context.queryKey[1]),
    select: selector,
    keepPreviousData: true,
    enabled: enabled,
    retry: false
  };
}
