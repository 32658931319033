import { Row, TableInstance } from "react-table";
import { EventMessage, FleetSenseDevice } from "../../api/data-contracts";
import { Constants } from "../../constants";
import { FilterParam } from "../../models/requests/shared.request";
import { DevicesSortFilterParamName, GetDevicesParams, GetDevicesResult } from "../../services/devices.service";
import { EventsSortAndFilterParamNames, GetEventsParams, GetEventsResult } from "../../services/events.service";
import { getEventMetadata } from "../../util/events.util";
import { messageToMediaDetails } from "../EventsTable/events-util";
import { FleetEntry } from "./FleetView";
import { dateUtils } from "../../util/date.util";

export function onClickRow(row: Row<FleetEntry>, table: TableInstance<FleetEntry>) {
  const isRowSelected = row.isSelected;
  table.toggleAllRowsSelected(false);
  if (!isRowSelected) {
    row.toggleRowSelected(true);
  }

  const isRowExpanded = row.isExpanded;
  table.toggleAllRowsExpanded(false);
  if (!isRowExpanded) {
    row.toggleRowExpanded(true);
  }
}

export function devicesToFleetEntries(devices: FleetSenseDevice[]): FleetEntry[] {
  return devices.map(
    (device, index): FleetEntry => ({
      index: index,
      id: device.id,
      vehicleType: !device.vehicleId && !device.lastVehicleId ? undefined : device.installationData?.VehicleType,
      licensePlate: device.installationData?.LicensePlateNumber,
      fleetName: device.fleetAssigned,
      lastEvent: !device.lastEventMessage
        ? undefined
        : {
            ...device.lastEventMessage,
            id: device.lastEventMessage.messageID,
            driverId: device.lastEventMessage.driverID,
            tripId: device.lastEventMessage.tripID,
            time: device.lastEventMessage.eventDateTime,
            type: device.lastEventMessage.eventType,
            speed: device.lastEventMessage.speed,
            heading: device.lastEventMessage.headingAngle,
            mediaArr: messageToMediaDetails(device.lastEventMessage),
            location: {
              address: device.lastEventMessage.lastKnownStreetAddress,
              time: device.lastEventMessage.locationTime,
              lat: device.lastEventMessage.lastKnownLocationLat || 0,
              lng: device.lastEventMessage.lastKnownLocationLong || 0,
            },
            eventAttr1: device.lastEventMessage?.eventAttr1,
            category: device.lastEventMessage.category,
          },
    })
  );
}

export function eventsQueryResultToTableData(queryResult: GetEventsResult) {
  return {
    pageCount: queryResult?.pageCount,
    totalCount: queryResult.totalCount,
    data: messagesToFleetEntries(queryResult?.data || []),
  };
}

function messagesToFleetEntries(messages: EventMessage[]): FleetEntry[] {
  return messages.map(
    (msg, index): FleetEntry => ({
      index,
      id: msg.deviceID,
      vehicleType: msg.vehicleType,
      licensePlate: msg.vehicleId,
      lastEvent: {
        id: msg.messageID,
        driverId: msg.driverID,
        tripId: msg.tripID,
        time: msg.eventDateTime,
        type: msg.eventType,
        speed: msg.speed,
        heading: msg.headingAngle,
        mediaArr: messageToMediaDetails(msg),
        location: {
          address: msg.lastKnownStreetAddress,
          time: msg.locationTime,
          lat: msg.lastKnownLocationLat || 0,
          lng: msg.lastKnownLocationLong || 0,
        },
        eventAttr1: msg.eventAttr1,
        category: msg.category,
      },
    })
  );
}

export function queryResultToTableData(queryResult: GetDevicesResult) {
  return {
    pageCount: queryResult?.pageCount,
    totalCount: queryResult.totalCount,
    data: devicesToFleetEntries(queryResult?.data || []),
  };
}

export function filterForEventsOfDevice(deviceId: string | undefined): FilterParam<EventsSortAndFilterParamNames> {
  return {
    paramName: "deviceID",
    operator: "==",
    values: deviceId ? [deviceId] : [],
  };
}
export function filterForEventsOfLicensePlate(
  vehicleId: string | undefined
): FilterParam<EventsSortAndFilterParamNames> {
  return {
    paramName: "licensePlate",
    operator: "==",
    values: vehicleId ? [vehicleId] : [],
  };
}

export function filterForEventsDate(
  filterParams: FilterParam<DevicesSortFilterParamName>[]
): FilterParam<EventsSortAndFilterParamNames>[] {
  return filterParams
    .filter((filter) => filter.paramName === "time")
    .map((filter) => {
      return { ...filter, paramName: "eventDateTime" };
    });
}

export const initialFleetViewQueryParams: GetDevicesParams = {
  filters: [
    { paramName: "time", operator: ">=", values: [dateUtils.addDays(new Date(), -30).toDateString()] },
    { paramName: "isActive", operator: "==", values: ["true"] },
    { paramName: "messageid", operator: "!=", values: ["0"] },
  ],
  sorts: [{ param: "time", descending: true }],
  paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  includeInstallationReport: true,
  geocoding: true,
};

export function getInitialTripEventsQueryParams(tripId?: number): GetEventsParams {
  return {
    filters: [
      { paramName: "tripId", operator: "==", values: [tripId?.toString() ?? ""] },
      { paramName: "status", operator: "==", values: ["0", "3"] },
    ],
    sorts: [{ param: "eventDateTime", descending: true }],
    paging: { pageIndex: 1, pageSize: -1 },
    geocoding: true,
  };
}

export function getInitialDriverEventsQueryParams(driverId?: string): GetEventsParams {
  var date = new Date();
  date.setDate(date.getDate() - 7); // Set date to 1 week ago
  return {
    filters: [
      { paramName: "driverId", operator: "==", values: [driverId ?? ""] },
      { paramName: "status", operator: "==", values: ["0", "3"] },
      { paramName: "eventDateTime", operator: ">=", values: [date.toDateString()] },
    ],
    sorts: [{ param: "eventDateTime", descending: true }],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
    geocoding: true,
  };
}

export function getInitialVehicleEventsQueryParams(vehicleId?: string): GetEventsParams {
  return {
    filters: [
      { paramName: "vehicleId", operator: "==", values: [vehicleId ?? ""] },
      { paramName: "status", operator: "==", values: ["0", "3"] },
    ],
    sorts: [{ param: "eventDateTime", descending: true }],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
    geocoding: true,
  };
}

export function getSafetyEvents(data?: FleetEntry[]) {
  return data?.filter((item) => getEventMetadata(item.lastEvent?.type)?.category === "Safety events") ?? [];
}
