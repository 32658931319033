import { useQuery } from "@tanstack/react-query";
import { reportsQuery } from "../queries/report.query";
import { useIntlContext } from "../context/IntlContext";
import { useTspFleetContext } from "../context/TspFleetContext/TspFleetContext";
import { useLastUpdateContext } from "../context/LastUpdateContext";
import { useEffect } from "react";

function useQuerySafetyAnalytics(enabled: boolean = true) {


  const { activeTsp, activeFleets } = useTspFleetContext();

  const {  updateTime } = useLastUpdateContext();

  const tspId = activeTsp ? activeTsp.id ?? 0 : 0;
  const fleetIdsString = activeFleets!.map(f => f.id).join("|");

  const { getCurrentLocale } = useIntlContext();
  const { data, isLoading, isError, isFetching, refetch } = useQuery(reportsQuery(tspId, fleetIdsString, getCurrentLocale() === "es-ES" ? "2" : "1", enabled));

  // useEffect(() => {
  //   setRefreshFunction(refetch);
  // }, []);

  useEffect(() => {
    if (data?.data.lastRefreshTime) {
      updateTime(new Date(data?.data.lastRefreshTime).toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { data, isLoading, isError, isFetching, refetch };
}

export default useQuerySafetyAnalytics;
