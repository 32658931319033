import { QueryFunctionContext } from "@tanstack/react-query";
import { reportService } from "../services/report.service";


export function reportsQuery(
  tspId: number,
  fleetIds: string,
  language: string,
  enabled: boolean
) {
  return {
    queryKey: ["safety-analytics-report", tspId,fleetIds,language] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["safety-analytics-report", number,string,string]>) =>
      await reportService.getReport(tspId,fleetIds,language),
    enabled: enabled,
    retry: false
  };
}
