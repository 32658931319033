import { useCallback } from "react";
import { dateUtils, getFrontDtFormat } from "../util/date.util";
import { useProfile } from "./useProfile";
export const useFormattedLocalDate = () => {
  const { dtFormat } = useProfile();
  const frontFormat = getFrontDtFormat(dtFormat)?.timeDate;

  const getFormattedLocalDate = useCallback(
    (date: string) => {
      return dateUtils.getFormattedLocalDate(date, frontFormat);
    },
    [frontFormat]
  );

   const getFormattedLocalTime = (date:string) => {

    return dateUtils.getFormattedTime(date)
  };

  return { getFormattedLocalDate, getFormattedLocalTime };
};
