import { CellProps, Column } from "react-table";
import { ConfigTableEntry } from "./ConfigTable";
import { FormattedMessage } from "react-intl";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import { ConfigSortFilterParamName, GetConfigResult } from "../../services/configuration.service";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import Form from "react-bootstrap/Form";
import { CONFIGS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

export function queryResultToTableData(config: GetConfigResult) {
  return {
    pageCount: config.pageCount,
    totalCount: config.totalCount,
    data: config.data?.map(
      (conf): ConfigTableEntry => ({
        ...conf,
      })
    ),
  };
}

export function generateConfigColumns(onStatusChanged: (data: ConfigTableEntry, active: boolean) => void): Column<ConfigTableEntry>[] {
  return [
    {
      id: "checkbox",
      Cell: ({ row }: CellProps<ConfigTableEntry>) => (
        <CheckboxInput
          checked={row.isSelected ? "FULL" : "EMPTY"}
          onClick={() => row.toggleRowSelected(!row.isSelected)}
        />
      ),
    },
    {
      accessor: "name",
      Header: (
        <SortingHeaderContainer<ConfigSortFilterParamName> context={CONFIGS_CONTEXT} paramName="name">
          <FormattedMessage id="NAME" defaultMessage="Name" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "version",
      Header: (
        <SortingHeaderContainer<ConfigSortFilterParamName> context={CONFIGS_CONTEXT} paramName="version">
          <FormattedMessage id="VERSION" defaultMessage="Version" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "description",
      Header: <FormattedMessage id="DESCRIPTION" defaultMessage="Description" />,
      Cell: ({ value }) => <div className="d-flex align-items-center gap-4">{value}</div>,
    },
    {
      accessor: "enabled",
      Header: <FormattedMessage id="STATUS" defaultMessage="Status" />,
      Cell: ({ value, row }) =>
        <div className="d-flex align-items-center gap-4">
          <>
            <Form.Check
              type="switch" className="with-image"
              checked={value}
              onChange={() => onStatusChanged(row.original, !value!)}
            />
            {
              value ? (<FormattedMessage id="ACTIVE" defaultMessage="Active" />)
                : (<FormattedMessage id="INACTIVE" defaultMessage="Inactive" />)
            }
          </>
        </div>
    },
    {
      accessor: "addedOn",
      Header: (
        <SortingHeaderContainer<ConfigSortFilterParamName> context={CONFIGS_CONTEXT} paramName="addedOn">
          <FormattedMessage id="CREATION_DATE" defaultMessage="Creation Date" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        const { getFormattedLocalDate } = useFormattedLocalDate();
        return <div>{value ? getFormattedLocalDate(value) : "--"}</div>;
      },
    },
  ];
}
