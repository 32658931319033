import { useEffect, useReducer, useRef } from "react";
import * as powerbi from "powerbi-client";
import useQuerySafetyAnalytics from "../../hooks/useQuerySafetyAnalytics";
import styles from "./SafetyAnalytics.module.scss";
import { useSafetyAnalyticsContext } from "../../context/SafetyAnalyticsContext ";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { modalInitialState, modalReducer } from "../../util/reducers/modal-reducer.util";
import EventDetailModal from "../EventsTable/modals/EventDetailModal";
import { useQueryTripEvents } from "../../hooks/useQueryEvents";


type EventModalName = "SHOW";

export interface EventTripModalData {
  rowIndex: number;
  queryParam: string;
}


const SafetyAnalyticsDashboard = () => {

  const reportRef = useRef<HTMLDivElement>(null);
  const powerbiService = useRef<powerbi.service.Service | null>(null);
  const { data } = useQuerySafetyAnalytics();
  const { pages, setReport } = useSafetyAnalyticsContext();
  const selectedEventRef = useRef<string | undefined>(undefined);
  const selectedTripRef = useRef<string | undefined>(undefined);
  const [modalState, dispatch] = useReducer(modalReducer<EventModalName, EventTripModalData>, modalInitialState);


  const navigate = useNavigate();

  const constructNavigateURL = (data: string): string => {
    const [startDate, endDate, driverId, eventType] = data.split("|");

    return `/events-list?from=${startDate}&to=${endDate}&driverId=${driverId}&eventType=${eventType}`;
};


  useEffect(() => {
    if (!data) return;

    if (powerbiService.current && reportRef.current) {
      powerbiService.current.reset(reportRef.current);
    }

    const config: powerbi.IEmbedConfiguration = {
      type: "report",
      tokenType: powerbi.models.TokenType.Embed,
      accessToken: data.data.token,
      embedUrl: data.data.embedUrl,
      settings: {
        panes: {
          pageNavigation: { visible: false },
        },
      },
    };

    const findEventListDataValue = (data: any, measure:string): string | undefined => {
      return data.dataPoints
        ?.flatMap((dp: any) => dp.values)
        ?.find((v: any) => v.target.measure === measure)
        ?.value;
    };



    const reportContainer = reportRef.current;
    powerbiService.current = new powerbi.service.Service(
      powerbi.factories.hpmFactory,
      powerbi.factories.wpmpFactory,
      powerbi.factories.routerFactory
    );

    const embeddedReport = powerbiService.current.embed(reportContainer!, config) as powerbi.Report;
    setReport(embeddedReport);


    embeddedReport.on("dataSelected", async (event) => {
      selectedEventRef.current = undefined
      selectedTripRef.current = undefined
      const page = await embeddedReport.getActivePage();

      if (page && page.name === '13cb7b33de098c390850') {
        const selectedEvent = findEventListDataValue(event.detail,"EVENT_LIST_DATA");
        selectedEventRef.current = selectedEvent;
      }
      if (page && page.name === '68530720e0d49dad6b01') {

        console.log(event.detail);
        const selectedTrip = findEventListDataValue(event.detail,"EVENT_DETAILS_DATA");
        selectedTripRef.current = selectedTrip;
      }
    });

    embeddedReport.on("buttonClicked", async (event) => {
      const page = await embeddedReport.getActivePage();
      if(page && page.name === '13cb7b33de098c390850' && selectedEventRef.current && selectedEventRef.current.split("|").length >= 4) {
        const url = constructNavigateURL(selectedEventRef.current);
        navigate(url);
      }
      if(page && page.name === '68530720e0d49dad6b01' && selectedTripRef.current && selectedTripRef.current.split("|").length >= 3) {
        dispatch({
          type: "SET_SHOW",
          payload: { modalName: "SHOW", data: { queryParam: selectedTripRef.current, rowIndex: 0 } },
        });
      }
    });

  }, [data, setReport]);


  return (

    <div className="flex-grow-1 position-relative overflow-auto">
      {pages.length === 0 && (
        <div>
          <Spinner as="span" animation="border" size="sm" className="m-auto" />
          Loading...
        </div>
      )}

      <div ref={reportRef} className={styles["powerbi-report"]}></div>

      {modalState.shownModal === "SHOW" && (

        <EventDetailModal
        onClickCancel={() => dispatch({ type: "CLEAR" })}
        index={modalState.data?.rowIndex || 0}
        queryParam={modalState.data?.queryParam || ""}
        useQueryFunction={useQueryTripEvents}
        showNavigation={false}
      />

      )}
      
    </div>
  );
};

export default SafetyAnalyticsDashboard;
