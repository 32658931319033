import { useMutation } from "@tanstack/react-query";
import Logger from "../util/logger.util";
import { GiveDriverFacePhotoMutation, TakeDriverFacePhotoMutation } from "../mutations/device-driver.mutation";
import { IGiveDriverFacePhoto, ITakeDriverFacePhoto } from "../services/device-driver.service";
import { ImageData } from "../api/data-contracts";
import { useToastContext } from "../context/ToastContext";
import { useIntl } from "react-intl";

function useMutationDeviceDriver(onSucessTakePhoto? : (enrollData: ImageData) => void) {
  const { add: addToast } = useToastContext();
  const { mutate: mutateTakeDriverFacePhoto, isLoading: isLoadingFaceCapture } = useMutation(TakeDriverFacePhotoMutation());
  const { mutate: mutateGiveDriverFacePhoto } = useMutation(GiveDriverFacePhotoMutation());
  const intl = useIntl();

 async function takeDriverFacePhoto(deviceDriver: ITakeDriverFacePhoto): Promise<void> {
    mutateTakeDriverFacePhoto(deviceDriver, {
      onSuccess: (response) => {
        Logger.info("Got photo: ", response.data);
        onSucessTakePhoto && onSucessTakePhoto(response.data);
      },
      onError: (error) => {
        let { response } = error;
        let data = response?.data as any;
        let reason = data?.Exception?.Reason;
        if(reason){

          const errorMessageId = "DRIVER_CAPTURE_ERROR_REASON" + reason;
          const translatedMessage = intl.messages[errorMessageId] 
            ? intl.formatMessage({ id: errorMessageId }) 
            : "Failed getting driver face photo";

          addToast({ type: "NEGATIVE", content: translatedMessage });
        }
        else{
          addToast({ type: "NEGATIVE", content: "Failed getting driver face photo" });
        }
      },
    });
  }

  async function giveDriverFacePhoto(deviceDriver: IGiveDriverFacePhoto): Promise<void> {
    mutateGiveDriverFacePhoto(deviceDriver, {
      onSuccess: () => {
        addToast({ type: "POSITIVE", content: "Saved driver face photo" });
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed saving driver face photo" });
      },
    });
  }
  return { takeDriverFacePhoto, giveDriverFacePhoto,isLoadingFaceCapture };
}

export default useMutationDeviceDriver;
