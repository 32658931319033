import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { CheckType } from "../../components/CheckboxInput/CheckboxInput";
import CipiaIcon from "../../assets/mocks/CipiaIcon.svg";
import useQueryTsps from "../../hooks/useQueryTsps";
import useQueryFleets from "../../hooks/useQueryFleets";
import { Fleet, SP } from "../../api/data-contracts";
import { useAuthContext } from "../AuthContext";
import { getTspAndFleetMaps, getTspIds, initializeState, tspFleetContextReducer } from "./tsp-fleet-context.util";
import { getIsCipiaRole, getIsFleetRole, getIsTspRole } from "../../util/roles.util";
import { useSearchParams } from "react-router-dom";
import { useLastUpdateContext } from "../LastUpdateContext";
import { fleetService } from "../../services/fleet.service";

export interface Tsp extends SP {
  children: Fleet[];
}
export interface ActiveTsp extends Tsp {
  checkType: CheckType;
}

export type SelectedType = Tsp | Fleet | "CIPIA";

/* Active Tsp/Fleets should be used for filtering (except when cipia is selected). Selected should be used to show its logo and name in the header */
type ITspFleetContext = {
  allTsps: Tsp[];
  activeTsp?: ActiveTsp;
  activeFleets: Fleet[];
  selected?: SelectedType;
  toggleCipia: () => void;
  toggleTsp: (tspId: number) => void;
  toggleFleet: (fleetId: number, exclusiveCheckAndSelect?: boolean) => void;
  getTspById: (id: number) => Tsp | undefined;
  getFleetById: (id: number) => Fleet | undefined;
};

const TspFleetContext = createContext<ITspFleetContext>({
  allTsps: [],
  activeTsp: { id: 1, companyFriendlyName: "tsp", checkType: "FULL", children: [] },
  activeFleets: [],
  selected: { id: 1, companyFriendlyName: "tsp", children: [], logo: CipiaIcon },
  toggleCipia: () => undefined,
  toggleTsp: () => undefined,
  toggleFleet: () => undefined,
  getTspById: () => undefined,
  getFleetById: () => undefined,
});

export function TspFleetContextProvider({ children }: PropsWithChildren<{}>) {
  const role = useAuthContext().loggedUser?.role;
  const isFleetRole = getIsFleetRole(role);
  const isTspRole = getIsTspRole(role);
  const isCipiaRole = getIsCipiaRole(role);

  const { autoRefreshIntervalInSec } = useLastUpdateContext();

  const { data: dataTsps, isLoading: isLoadingTsps, refetch: refetchTsp } = useQueryTsps({ enabled: role ? !isFleetRole : false });
  const tspIds = useMemo(() => getTspIds(dataTsps?.data || []), [dataTsps?.data]);
  const { data: dataFleets, isLoading: isLoadingFleets, refetch: refetchFleet } = useQueryFleets({
    enabled: role ? !isFleetRole && !!tspIds.length : false,
    tspIds: isTspRole ? undefined : tspIds,
  });

  const [tspIdToTsp, fleetIdToFleet]: [Map<number, Tsp>, Map<number, Fleet>] = useMemo(() => {
    if (!dataTsps?.data || !dataFleets?.data) return [new Map(), new Map()];
    return getTspAndFleetMaps(dataTsps.data, dataFleets.data);
  }, [dataTsps?.data, dataFleets?.data]);

  const [state, dispatch] = useReducer(tspFleetContextReducer, { tspIdToTsp, fleetIdToFleet, role }, initializeState);

  useEffect(() => {
    //On refetch, update state
    dispatch({ type: "UPDATE_STATE_ON_REFETCH", payload: { tspIdToTsp, fleetIdToFleet, role } });
  }, [tspIdToTsp, fleetIdToFleet, role]);

  const toggleCipia = useCallback(() => {
    dispatch({ type: "TOGGLE_CIPIA" });
  }, []);
  const toggleTsp = useCallback((tspId: number) => {
    dispatch({ type: "TOGGLE_TSP", payload: { tspId } });
  }, []);
  const toggleFleet = useCallback((fleetId: number, exclusiveCheckAndSelect?: boolean) => {
    dispatch({ type: "TOGGLE_FLEET", payload: { fleetId, exclusiveCheckAndSelect } });
  }, []);

  const allTsps = useMemo(() => Array.from(tspIdToTsp.values()), [tspIdToTsp]);

  const getTspById = useCallback((id: number) => state.tspIdToTsp.get(id), [state.tspIdToTsp]);
  const getFleetById = useCallback((id: number) => state.fleetIdToFleet.get(id), [state.fleetIdToFleet]);

  let [searchParams] = useSearchParams();

  const isInitFromUrlDone = useRef({
    tspId: false,
    fleetId: false,
  });

  // const initTspFromUrlDone = useRef(false);
  // const initFleetIdsFromUrlDone = useRef(false);

  useEffect(() => {
    if (isInitFromUrlDone.current.tspId || state.activeTsp || isLoadingTsps || !state.tspIdToTsp.size) return;

    const urlTspId = searchParams.get("tspId");

    if (!urlTspId) return;

    const urlFleetId = searchParams.get("fleetId");

    if (urlFleetId) return;

    // Init TSP from route if exists
    toggleTsp(Number(urlTspId));
    isInitFromUrlDone.current.tspId = true;
  }, [isLoadingTsps, searchParams, state.activeTsp, state.tspIdToTsp.size, toggleTsp]);

  useEffect(() => {
    if (isInitFromUrlDone.current.fleetId || state.activeFleets.length || isLoadingFleets || !fleetIdToFleet.size)
      return;

    const urlFleetId = searchParams.get("fleetId");

    if (!urlFleetId) return;

    let fleetIds = urlFleetId.split("|");

    if (state.selected !== "CIPIA") {
      fleetIds = fleetIds.filter((fleetId) =>
        (state.selected as Tsp)?.children?.map((child) => child.id).includes(Number(fleetId))
      );
    }

    // Init Fleet IDs from route if exists
    fleetIds.forEach((fleetId) => {
      toggleFleet(Number(fleetId));
    });

    isInitFromUrlDone.current.fleetId = true;
  }, [fleetIdToFleet.size, isLoadingFleets, searchParams, state.activeFleets.length, state.selected, toggleFleet]);

  useEffect(() => {
    if (autoRefreshIntervalInSec && autoRefreshIntervalInSec > 0 && (isCipiaRole || isTspRole)) {

      const intervalId = setInterval(async () => {
        let tspId = 0;
        if (dataFleets?.data && dataFleets?.data.length > 0) {
          tspId = dataFleets?.data[0].tspId ?? 0;
        }

        if(isTspRole && tspId === 0){ 
          if (dataTsps?.data && dataTsps?.data.length > 0) {
            tspId = dataTsps?.data[0].id ?? 0;
          }
        }

        const fleetCount = await fleetService.getFleetCount(isCipiaRole ? 0 : tspId, dataTsps?.totalCount ?? 0, dataFleets?.totalCount ?? 0);
        if (fleetCount > 0) {
          refetchTsp();
          refetchFleet();
        }
      }, autoRefreshIntervalInSec * 1000);
      return () => clearInterval(intervalId);
    }
  }, [autoRefreshIntervalInSec, refetchTsp, refetchFleet, dataFleets, isCipiaRole, isTspRole, dataTsps]);

  return (
    <TspFleetContext.Provider
      value={{
        allTsps,
        activeTsp: state.activeTsp,
        activeFleets: state.activeFleets,
        selected: state.selected,
        toggleCipia,
        toggleTsp,
        toggleFleet,
        getTspById,
        getFleetById,
      }}
    >
      {children}
    </TspFleetContext.Provider>
  );
}


export const useTspFleetContext = () => useContext<ITspFleetContext>(TspFleetContext);