import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Color, RouteConstants } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";
import useAuthentication from "../../hooks/useAuthentication";
import { NavLink } from "react-router-dom";
import styles from "./NavBar.module.scss";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import cipiaLogoPath from "../../assets/Cipia.svg";
import userIconPath from "../../assets/user.svg";
import { FormattedMessage } from "react-intl";
import Arrow, { Direction } from "../../icons/Arrow";
import { useState } from "react";
import { LangNamesType, useIntlContext } from "../../context/IntlContext";

//Renders the User name button and the Popover with email
function UserInfo() {
  const { loggedUser: { fullName, email } = {} } = useAuthContext();
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id="popover-user">
          <Popover.Body className="d-flex align-items-center">
            <img src={userIconPath} alt="usr" />
            <div className="text-left ms-3 ">
              {fullName && (
                <div>
                  <span>{fullName}</span>
                  <br />
                </div>
              )}
              {email}
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      <Button className="my-1" variant="white" style={{ color: "white" }}>
        <img src={userIconPath} alt="usr" />
        <span className="p-2">{fullName}</span>
      </Button>
    </OverlayTrigger>
  );
}

function NavBar() {
  const { logout } = useAuthentication();
  const { changeLanguage, getLangNames, getCurrentLanguage } = useIntlContext();
  const [langDropdownOpen, setLangDropdownOpen] = useState<boolean>(false);
  const navBtnClassName = ({ isActive }: { isActive: boolean }) =>
    `${styles["nav-btn"]} ${isActive ? styles["nav-btn--active"] : ""} px-3`;

  return (
    <Navbar className={styles.nav_container} expand="lg">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto m-4">
          <Nav.Link
            as={() => (
              <NavLink to={RouteConstants.SAFETY_ANALYTICS} className={navBtnClassName}>
                <FormattedMessage id="SAFETY_ANALYTICS" defaultMessage="Safety Analytics" />
              </NavLink>
            )}
          ></Nav.Link>
          <Nav.Link
            as={() => (
              <NavLink to={RouteConstants.EVENTS_ON_MAP} className={navBtnClassName}>
                <FormattedMessage id="EVENTS_ON_MAP" defaultMessage="Events on Map" />
              </NavLink>
            )}
          ></Nav.Link>
          <Nav.Link
            as={() => (
              <NavLink to={RouteConstants.EVENTS_LIST} className={navBtnClassName}>
                <FormattedMessage id="EVENTS_LIST" defaultMessage="Events List" />
              </NavLink>
            )}
          />
          <Nav.Link
            as={() => (
              <NavLink to={RouteConstants.MANAGEMENT} className={navBtnClassName}>
                <FormattedMessage id="MANAGEMENT" defaultMessage="Management" />
              </NavLink>
            )}
          />
        </Nav>
        <Nav className="ms-auto">
          <UserInfo />
          <Nav.Link
            as={() => (
              <NavLink to={`/${RouteConstants.AUTH}/${RouteConstants.LOGIN}`} onClick={() => logout()}>
                <Button className="m-1" variant="link" style={{ color: `${Color.CIPIA_BLUE}` }}>
                  <FormattedMessage id="LOGOUT" defaultMessage="Logout" />
                </Button>
              </NavLink>
            )}
          />
          <NavDropdown
            className={`py-1 ${styles["dropdown-toggle"]}`}
            onClick={() => setLangDropdownOpen(!langDropdownOpen)}
            title={
              <>
                <span style={{ margin: "0.5rem", color: `${Color.CIPIA_BLUE}` }}>{getCurrentLanguage()!.long}</span>
                <Arrow direction={langDropdownOpen ? Direction.Up : Direction.Down} color={Color.CIPIA_BLUE} />
              </>
            }
          >
            {getLangNames()!.map((names: LangNamesType) => (
              <NavDropdown.Item key={`lang-${names.short}`} onClick={() => changeLanguage(names.short)}>
                {names.long}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <Nav.Link href="#logo">
            <img className="img-fluid" src={cipiaLogoPath} alt="Logo" />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
