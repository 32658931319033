import { PropsWithChildren } from "react";
import { Constants } from "../../constants";
import { EventsSortAndFilterParamNames, GetEventsParams } from "../../services/events.service";
import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { dateUtils } from "../../util/date.util";
import { EVENTS_CONTEXT } from "./queryParamContextObjects";

import { useSearchParams } from "react-router-dom";

const useInitialEventsParams = (): GetEventsParams => {
  const [searchParams] = useSearchParams();
  
  const fromDateFromUrl = searchParams.get("from") || dateUtils.getFormattedDate(dateUtils.addDays(new Date(), -30), "YYYY-MM-DD");

  return {
    filters: [
      {
        paramName: "eventDateTime",
        operator: ">=",
        values: [fromDateFromUrl], 
        includeInUrl: true,
        urlParamName: "from",
      },
      {
        paramName: "status",
        operator: "==",
        values: ["0", "3"],
      },
      {
        paramName: "category",
        operator: "!=",
        values: ["3"],
      },
    ],
    sorts: [{ param: "eventDateTime", descending: true }],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
    geocoding: true,
  };
};


export function getTripEventsInitialParams(tripId?: number): GetEventsParams {
  return {
    filters: [
      { paramName: "tripId", operator: "==", values: [tripId?.toString() ?? ""] },
      { paramName: "status", operator: "==", values: ["0", "3"] },
    ],
    sorts: [{ param: "eventDateTime", descending: true }],
    paging: { pageIndex: 1, pageSize: -1 },
    geocoding: true,
  };
}

 
export function EventsQueryParamsContextProvider({
  initialParams,
  children,
}: PropsWithChildren<{ initialParams?: GetEventsParams }>) {
  const initialEventsParams = useInitialEventsParams();
  return (
    <QueryParamsContextProvider context={EVENTS_CONTEXT} initialParams={initialParams ?? initialEventsParams}>
      {children}
    </QueryParamsContextProvider>
  );
}

export const useEventsQueryParamsContext = () =>
  useQueryParamsContext<EventsSortAndFilterParamNames, EventsSortAndFilterParamNames>(EVENTS_CONTEXT);
